// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD5TtITYGrXxlWNRbbWN3UYwTep8jZfIwA",
  authDomain: "hollrs-app-test.firebaseapp.com",
  projectId: "hollrs-app-test",
  storageBucket: "hollrs-app-test.appspot.com",
  messagingSenderId: "320198042375",
  appId: "1:320198042375:web:5fb3afb69ef251eb995ba2",
  measurementId: "G-5SQCB4YRME",
  qrCodeDataFunction: "https://europe-west2-hollrs-app-test.cloudfunctions.net/qrCodeData",
};
